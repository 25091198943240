import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const DarlehenUndKredit = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Unterschied zwischen Darlehen und Kredit" showCalc={false} />
            <Article>
                <p>
                    Du bist auf der Suche nach einer Kredit, um deinen Wohntraum Wirklichkeit werden zu lassen, in
                    Finanzierungsfragen allerdings noch nicht ganz so firm? Sei unbesorgt! Wir erklären dir Schritt für
                    Schritt alle relevanten Grundbegriffe – im folgenden Artikel sehen wir uns das Darlehen genauer an.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Kreditvergleich anfordern!
                    </a>
                </p>
                <hr />

                <h2>Was ist ein Darlehen?</h2>
                <p>
                    Unter Darlehen versteht man den Verleih von Kapital zu Finanzierungszwecken. Die entsprechende Summe
                    muss in einem vertraglich festgelegten Zeitraum zurückgezahlt werden. Zentral ist hierbei, dass der
                    Darlehensvertrag erst dann zustandekommt, wenn der jeweilige Betrag an den*die Kreditnehmer*in
                    ausgezahlt wurde.
                </p>
                <hr />

                <h2>Was ist der Unterschied zwischen Darlehen und Kredit?</h2>
                <p>
                    Auch wenn die beiden Begriffe in der Alltagssprache oft synonym verwendet werden, gilt: Das Darlehen
                    ist eine spezielle Form des{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>
                    , bei der besonders hohe Kapitalsummen verliehen werden und die eine vergleichsweise lange Laufzeit
                    aufweist. Im Unterschied zu Krediten, die in Österreich eine Laufzeit von maximal 4 Jahren haben,
                    können Darlehen über einen Zeitraum von 10 bis 40 Jahren zurückgezahlt werden. Das Volumen des
                    verliehenen Betrags wirkt sich natürlich auf die{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    aus: Diese sind im Falle des Darlehens üblicherweise geringer als bei einem Kredit, ja können hier
                    sogar gänzlich entfallen, sodass tatsächlich nur die geliehene Summe zurückerstattet werden muss.
                    Anders als bei Darlehen besteht bei Kredit ferner bereits mit der Vertragsunterzeichnung ein{" "}
                    <Link to="/artikel/schulden/" target="_blank" rel="noreferrer noopener">
                        Schuldverhältnis
                    </Link>
                    . Ob der entsprechende Geldbetrag bereits an den*die Kreditnehmer*in transferiert wurde, ist dabei
                    unerheblich.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Kreditvergleich anfordern!
                    </a>
                </p>
                <hr />

                <h2>Welche Arten von Darlehen gibt es?</h2>
                <h3>Partiarisches Darlehen</h3>
                <p>
                    Eine Spielart der Beteiligungsfinanzierung ist das partiarische Darlehen (auch
                    Beteiligungsdarlehen). Bei diesem erhält der*die Kreditgeber*in meist keine Zinsen, sondern wird
                    stattdessen am Gewinn eines Unternehmens beteiligt. Die Vereinbarung von Zinsen ist allerdings
                    dennoch möglich.
                </p>
                <h3>Privat an privat</h3>
                <p>
                    Auch wenn du dir größere Geldsummen von Verwandten, Freund*innen oder sonstigen Privatpersonen aus
                    deinem Umfeld leihst, handelt es sich bei dieser Transaktion um ein Darlehen – genauer um ein
                    Privatdarlehen: Vetragspartner*innen sind hier zwei natürliche Personen. Zwar besteht zwischen
                    diesen in den meisten Fällen ein Vertrauensverhältnis. Um Rechtsstreitigkeiten zu vermeiden,
                    empfiehlt es sich allerdings auch hier, einen Darlehensvertrag zu erstellen.
                </p>
                <h3>Darlehen von Sozialämtern und Bundesländern</h3>
                <p>
                    Befindest du dich in einer finanziellen Notlage und bist nicht mehr imstande, basale Bedürfnisse
                    abzudecken, dann kannst du bei verschiedenen staatlichen Stellen um ein (meist zinsfreies) Darlehen
                    ansuchen. Informiere dich am besten bei lokalen sozialen Einrichtungen oder der website deines
                    Bundeslands über die Möglichkeiten, ein solches Darlehen zu erhalten.
                </p>
                <h3>Darlehen speziell für selbständig tätige Personen</h3>
                <p>
                    Neben Kreditinstituten kannst du als Selbständige*r auch auf anderen Wegen an Kapital kommen: Etwa
                    durch Förderstellen oder Plattformen für Unternehmer*innen, auf denen Mezzaninkapital
                    (Beteiligungskapital) angeboten wird.
                </p>
                <p>
                    Du bist Jungunternehmer*in und noch nicht imstande, 3 Jahresabschlüsse vorzulegen, weshalb du von
                    Kreditinstituten eher niedrig geratet wirst? Unsere Finanzexpert*innen helfen dir dabei, die{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    deines Unternehmens zu verbessern und doch noch ein Darlehen zu erhalten.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Beratungsgespräch vereinbaren!
                    </a>
                </p>
                <h3>Darlehen beim Erwerb von Immobilien</h3>
                <p>
                    In den meisten Fällen wird dieses von einem Kreditinstitut, in selteneren Fällen von natürlichen
                    oder juristischen Personen vergeben. Die Immobilie, für deren Finanzierung das Darlehen beantragt
                    wurde, dient dem*der Kreditgeber*in dabei als Sicherheit. Dennoch solltest du idealerweise 30,
                    zumindest aber 20% des Kaufpreises mit Eigenkapital bestreiten. Noch ein kleiner Tipp: Mit unserem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    kannst du dir schon vor einem Beratungstermin ansehen, welche Kosten bei einer Finanzierung etwa
                    anfallen werden.
                </p>
                <hr />

                <h2>Noch Fragen? Frag miracl!</h2>
                <p>
                    Sich einen ersten Überblick im Dickicht der Darlehensformen zu verschaffen, kann frustrierend sein!
                    Umso mehr, wenn man im hektischen Alltag kaum Zeit findet, sich eingehend mit diesem Thema zu
                    beschäftigen. Wir kennen das selbst nur allzu gut! Deswegen bieten wir dir bei miracl die
                    größtmögliche Flexibilität: Mit persönlicher Online-Beratung. Unsere Finanzexpert*innen stehen dir
                    bei allen Phasen des Immobilienkaufs mit Rat und Tat zur Seite. So erhältst auch du ein Darlehen, da
                    lehnen wir uns nicht zu weit aus dem Fenster!
                </p>
            </Article>

            <BreadcrumbList page={"darlehenUndKredit"}></BreadcrumbList>
            <ArticleStructuredData page={"darlehenUndKredit"} heading={"Unterschied zwischen Darlehen und Kredit"} />
        </Layout>
    );
};

export default DarlehenUndKredit;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.darlehen-und-kredit", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
